<template>
  <div>
    <RightClickMenu ref="rightClickMenu" :rightClickItems="rightClickItems" />
    <SettingModal ref="settingModal" v-if="form" />

    <v-dialog v-model="isOpen" persistent max-width="1200px" v-if="form" content-class="ma-0 SaveFormModal-dialog">
      <v-card @contextmenu="$refs.rightClickMenu.show" class="slide-content">
        <div class="scroller">

          <!-- title -->
          <div class="d-flex align-center pa-3 pl-6">
            <v-text-field hide-details placeholder="Nom du formulaire" v-model="form.name" v-if="!preview" class="mt-0 pt-0 mr-4" />
            <h3 v-else>{{ form.name }}</h3>
            <v-spacer v-if="preview" />
            <v-tooltip bottom>
              <template #activator="tooltip">
                <v-btn large icon @click="onCloseClick" v-on="{ ...tooltip.on }">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Fermer le formulaire</span>
            </v-tooltip>
          </div>

          <!-- carousel -->
          <v-carousel class="carousel" v-model="selectedSlide" :show-arrows="form.slides.length > 1 && landscapeScreen" touchless height="550px" hide-delimiters>
            <v-carousel-item v-for="slide, index of form.slides" v-bind:data="slide" v-bind:key="index"
              @mouseover="addToRightClick" @mouseleave="delToRightClick">
              <ContextSlide  :form="form" :slide="slide" :slideIndex="index" :preview="preview" :rightClickItems="rightClickItems" :profile="profile" v-if="slide.layout === 'context'" :landscapeScreen="landscapeScreen" />
              <QuestionSlide :form="form" :slide="slide" :slideIndex="index" :preview="preview" :rightClickItems="rightClickItems" :profile="profile" v-if="slide.layout === 'question'" :selectedSlide="selectedSlide" :editData="editData" />

              <div v-else-if="!preview" style="height: 100%;" class="d-flex align-center justify-center gap-l">
                <v-btn outlined @click="addSlide('context')">Contexte</v-btn>
                <v-btn outlined @click="addSlide('question')">Questions</v-btn>
              </div>

            </v-carousel-item>
          </v-carousel>
        </div>

        <!-- bottom -->
        <div class="bottom-menu">
          <div class="px-3 gap-s">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="preview = !preview" v-bind="attrs" v-on="on">
                  <v-icon>{{ preview ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ preview ? 'Afficher un prérendu' : 'Cacher le prérendu' }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!preview">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="$refs.settingModal.open(form)">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <span>Paramètres du formulaire</span>
            </v-tooltip>
            <v-tooltip bottom
              v-if="form.slides[selectedSlide].content && form.slides[selectedSlide].content.questions && !preview"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="variableModal = true">
                  <v-icon>mdi-alpha-v-circle</v-icon>
                </v-btn>
              </template>
              <span>Variables de la slide</span>
            </v-tooltip>
            <!-- DEBUG --><v-btn v-if="profile.dev" outlined color="debug" @click="tmp_code_modal = true; tmp_code = form.slides[selectedSlide]">SLIDE</v-btn>
          </div>
          <v-divider vertical />
          <CarouselSlideChanger :form="form" :actualSlide="selectedSlide" :rightClickItems="rightClickItems" :editable="true" :editData="editData"
            @change="i => selectedSlide = i" @del="i => delSlide(i)"
          />
          <v-divider vertical />
          <div class="px-3 gap-s">
            <!-- DEBUG --><v-btn v-if="profile.dev" outlined color="debug" @click="tmp_code_modal = true; tmp_code = form">FORM</v-btn>
            <v-tooltip bottom>
              <template #activator="tooltip">
                <v-btn icon color="primary" @click="save" v-on="{ ...tooltip.on }">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Sauvegarder</span>
            </v-tooltip>
          </div>
        </div>
        <!-- DEBUG --><v-dialog v-if="profile.dev" v-model="tmp_code_modal" width="fit-content">
          <v-card style="width: fit-content;overflow-x: hidden">
            <pre>{{ tmp_code }}</pre>
          </v-card>
        </v-dialog>
      </v-card>


      <!-- variables -->
      <v-dialog v-model="variableModal" width="fit-content" v-if="form.slides[selectedSlide].content && form.slides[selectedSlide].content.questions">
        <v-card width="fit-content">
          <v-card-title>Variables</v-card-title>
          <v-card-text v-if="form.slides[selectedSlide].content.questions.filter(x => x.var).map(x => x.var).length > 0">
            <div v-for="(question, index) of form.slides[selectedSlide].content.questions" class="d-flex align-center" v-if="question.var">
              <v-btn icon color="info" small @click="copyToClipBoard(question.var)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
              <div ref="variables" class="variable" @blur="saveVariable(index)" @click="editVariable(index)">{{ question.var }}</div>
              <v-divider class="ml-3" />
              <v-btn icon color="error" @click="delVariable(index)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
            <div class="mt-3 caption">Les variables sont utilisées avec les questions <strong>dépendantes</strong>.</div>
          </v-card-text>
          <v-card-text v-else>
            <div>Il n'y a aucune variable, vous pouvez en ajouter en faisant un <strong>clique droit</strong> sur une question à <strong>réponse numérique.</strong></div>
            <div>Les variables sont utilisées avec les questions <strong>dépendantes</strong>.</div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { getStorage, ref, uploadBytes, deleteObject } from 'firebase/storage'
import SlideForm from '@/classes/SlideForm'
import SettingModal from '@/components/SlideForm/SettingModal.vue'
import ContextSlide from '@/components/SlideForm/ContextSlide.vue'
import QuestionSlide from '@/components/SlideForm/QuestionSlide.vue'
import RightClickMenu from '@/components/SlideForm/RightClickMenu.vue'
import CarouselSlideChanger from '@/components/SlideForm/CarouselSlideChanger.vue'
import FormResponse from '@/classes/FormResponse'
import Note from '@/classes/Note'

export default {
  props: ['profile'],
  components: { SettingModal, ContextSlide, QuestionSlide, RightClickMenu, CarouselSlideChanger },
  data() {
    return {
      /*DEBUG*/tmp_code: null,
      /*DEBUG*/tmp_code_modal: false,
      storage: getStorage(),
      isOpen: false,
      form: null,
      selectedSlide: 0,
      defaultform: new SlideForm(null, '', this.profile.currentRoom, 'standard', false, null, null, [{}], false),
      defaultContextSlide: {
        layout: 'context',
        content: {
          layout: 'empty'
        }
      },
      defaultQuestionSlide: {
        layout: 'question',
        content: {},
        hint: '',
        textCorrection: ''
      },
      preview: false,

      rightClickItems: [
        {
          text: 'Ajouter une slide',
          method: () => {
            this.form.slides.push({})
            this.selectedSlide = this.form.slides.length - 1
            this.preview = false
          }
        }
      ],
      variableModal: false,
      varEditableIndex: null,
      landscapeScreen: true,
      editData: {
        thereAreResponses: false,
        isEdited: false,
        isCriticEdited: false
      }
    }
  },
  created() {
    if (window.innerWidth < window.innerHeight) this.landscapeScreen = false
  },
  destroyed() {
    window.removeEventListener('resize', this.checkScreen, true)
  },
  methods: {
    open(form = null) {
      if (form) {
        this.form = Object.assign(new SlideForm(), JSON.parse(JSON.stringify(form)))
        FormResponse.getByForm(this.form.id).then(responses => {
          if (responses.length > 0) {
            this.editData.thereAreResponses = true
            Swal.fire({
              title: 'Attention',
              text: 'Des utilisateurs ont déjà répondu à ce formulaire, certaines modifications pourraient corrompre leurs réponses. Voulez-vous continuer ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Continuer',
              cancelButtonText: 'Non',
            }).then(result => {
              if (result.isConfirmed) 
                this.isOpen = true
            })
          } else {
            this.isOpen = true
          }
        })
      }
      else {
        this.isOpen = true
        this.form = Object.assign(new SlideForm(), JSON.parse(JSON.stringify(this.defaultform)))
      }

      this.checkScreen()
      window.addEventListener('resize', this.checkScreen, true)
    },
    onCloseClick() {
      Swal.fire({
        text: 'Voulez vous sauvegarder les modifications ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sauvegarder',
        cancelButtonText: 'Non',
      }).then(result => {
        if (result.isConfirmed) this.save()
        else this.close()
      })
    },
    close() {
      window.removeEventListener('resize', this.checkScreen, true)
      this.preview = false
      this.form = null
      this.selectedSlide = 0
      this.editData.isEdited = false
      this.editData.isCriticEdited = false
      this.isOpen = false
    },
    addSlide(layout) {
      this.form.slides[this.selectedSlide] = JSON.parse(JSON.stringify(
        layout === 'context' ? this.defaultContextSlide : this.defaultQuestionSlide
      ))
      this.editData.isEdited = true
      this.editData.isCriticEdited = true
      this.$forceUpdate()
    },
    delSlide(i) {
      const slideDeleted = i ? i : this.selectedSlide

      Swal.fire({
        title: 'Etes-vous sûr ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Annuler',
      }).then(result => {
        if (result.isConfirmed) {
          if (this.form.deletedFiles === undefined) this.form.deletedFiles = []
          if (this.form.slides[slideDeleted].content && this.form.slides[slideDeleted].content.media) {
            this.form.deletedFiles.push(this.form.slides[slideDeleted].content.media.path)
          } else if (
            this.form.slides[slideDeleted].layout === 'question' &&
            this.form.slides[slideDeleted].content.question &&
            this.form.slides[slideDeleted].content.question.type === 'selectImage'
          ) {
            for (const choice of this.form.slides[slideDeleted].content.question.choices) {
              if (choice) {
                this.form.deletedFiles.push(choice.path)
              }
            }
          }

          if (this.form.slides.length > 1) {
            this.form.slides.splice(slideDeleted, 1)
          } else {
            this.form.slides = [{}]
          }

          if (slideDeleted === this.selectedSlide) this.selectedSlide--
          if (this.selectedSlide <= 0) this.selectedSlide = 0

          
          this.editData.isEdited = true
          this.editData.isCriticEdited = true
        }
      })
    },
    async save() {
      // error
      let error = null
      if (this.form.name.trim() === '') error = 'Le formulaire a besoin d\'un titre.'
      else if (this.form.slides.length === 0) error = 'Le formulaire ne comporte aucune slide.'
      this.form.slides.forEach((slide, index) => {
        if (slide.content === undefined ||
          (slide.layout === 'context' && (!slide.title && !slide.content.text && !slide.content.media))
        ) {
          this.selectedSlide = index
          error = 'Cette slide est vide. (Vous pouvez la supprimer)'
          return
        } else if (slide.title != undefined && slide.title.trim() === '') {
          this.selectedSlide = index
          error = 'Ce titre est vide. (Vous pouvez le supprimer)'
          return
        } else if (slide.content.text != undefined && slide.content.text.trim() === '') {
          this.selectedSlide = index
          error = 'Ce texte est vide. (Vous pouvez le supprimer)'
          return
        } else if (slide.content.question && slide.content.question.type === 'classification') {
          let allResponse = []

          for (const cat of slide.content.question.goodResponse) {
            allResponse = allResponse.concat(cat.choices)
          }
          const setAllResponse = new Set(allResponse)

          if (allResponse.length !== setAllResponse.size) {
            this.selectedSlide = index
            error = 'Les questions de type classification ne peuvent pas contenir deux choix égaux.'
            return
          }
        }
      })
      if (error) {
        Swal.fire({
          title: 'Erreur',
          text: error,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }

      else {
        // critic
        let criticConfirmed = false
        if (this.editData.thereAreResponses && this.editData.isCriticEdited) {
          const result = await Swal.fire({
            title: 'Attention',
            text: 'Des modifications critiques ont été trouvées, si vous continuez les réponses des utilisateurs à ce formulaire seront supprimées. Voulez-vous continuer ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler'
          })
          if (!result.isConfirmed)
            return
          else
            criticConfirmed = true
        }


        let nbQuestion = 0
        let totalPoint = 0

        for (const slide of this.form.slides.filter(x=>x.layout==='question')) {
          if (slide.content.type === 'simple') {
            nbQuestion += slide.content.questions.length
            for (const question of slide.content.questions.filter(x=>!['free', 'rating'].includes(x.type))) {
              totalPoint += parseFloat(question.points)
            }
          } else if (slide.content.type === 'advanced') {
            nbQuestion ++
            totalPoint += parseFloat(slide.content.question.points)
          }
        }

        const userConfirmation = await Swal.fire({
          title: 'Sauvegarder le formulaire ?',
          html: `
            Ce formulaire contient ${this.form.slides.length} slide${this.form.slides.length > 1 ? 's' : ''} et ${nbQuestion} question${nbQuestion > 1 ? 's' : ''}.<br />
            Il est noté sur ${totalPoint} et ${this.form.pointsConvertion === null ? `n'est pas converti` : `est converti sur ${this.form.pointsConvertion}`}.
          `,
          showCancelButton: true,
          confirmButtonText: 'Sauvegarder',
          cancelButtonText: 'Continer de modifier'
        })
        if (userConfirmation.isConfirmed) {
          if (criticConfirmed) {
            // delete responses
            FormResponse.getByForm(this.form.id).then(responses => {
              for (const res of responses) res.delete()
            })

            // update note
            Note.getByForm(this.form.id).then(notes => {
              for (const note of notes) {
                note.form = null
                note.slideFormNote = false
                note.save()
              }
            })
          }

          // save
          if (!this.form.id) {
            this.form.id = await SlideForm.getANewId()
            this.form.isNewForm = true
          }

          // deleted files
          if (this.form.deletedFiles) {
            for (const filePath of this.form.deletedFiles) {
              const fileRef = ref(this.storage, filePath)
              deleteObject(fileRef)
            }
            delete this.form.deletedFiles
          }

          // save media
          await Promise.all(this.form.slides.map(async (slide, index) => {
            if (slide.content.media && slide.content.media.isUpdated) {
              slide.content.media.path = 'slideForms/' + this.form.id + '/slide' + index
              const fileRef = ref(this.storage, slide.content.media.path)
              await uploadBytes(fileRef, slide.content.media.file)
              delete slide.content.media.ref
              delete slide.content.media.file
              delete slide.content.media.isUpdated
            }

            // save image from select image question
            if (slide.layout === 'question' && slide.content.question && slide.content.question.type === 'selectImage') {
              await Promise.all(slide.content.question.choices.map(async (choice, yndex) => {
                if (choice && choice.isUpdated) {
                  choice.path = 'slideForms/' + this.form.id + '/slide' + index + '/' + yndex
                  const fileRef = ref(this.storage, choice.path)
                  await uploadBytes(fileRef, choice.file)
                  delete choice.ref
                  delete choice.file
                  delete choice.isUpdated
                }
              }))
            }
          }))

          this.form.save().then(() => {
            this.close()
            Swal.fire({
              title: 'Formulaire Validé',
              icon: 'success',
              confirmButtonText: 'Ok'
            })
          })
        }
      }
    },

    // variables
    delVariable(index) {
      delete this.form.slides[this.selectedSlide].content.questions[index].var
      this.$forceUpdate()
    },
    editVariable(index) {
      this.varEditableIndex = index

      let refIndex = 0
      this.form.slides[this.selectedSlide].content.questions.forEach((question, tmp_index) => {
        if (tmp_index < index && question.var) refIndex++
      })
      const el = this.$refs.variables[refIndex]

      el.addEventListener('keydown', event => {
        if (['Enter', 'Escape'].includes(event.key)) this.saveVariable(index)
      })
      
      el.contentEditable = true
      const end = el.innerText.length
      
      const range = document.createRange()
      const sel = window.getSelection()
      
      range.setStart(el.childNodes[0], end)
      range.collapse(true)
      
      sel.removeAllRanges()
      sel.addRange(range)
    },
    saveVariable(index) {
      this.varEditableIndex = null
      
      let refIndex = 0
      this.form.slides[this.selectedSlide].content.questions.forEach((question, tmp_index) => {
        if (tmp_index < index && question.var) refIndex++
      })
      const el = this.$refs.variables[refIndex]

      const oldVal = this.form.slides[this.selectedSlide].content.questions[index].var
      const newVal = el.innerText.replaceAll('[', '').replaceAll(']', '')
      el.innerText = newVal

      el.contentEditable = false
      this.form.slides[this.selectedSlide].content.questions[index].var = newVal

      for (const question of this.form.slides[this.selectedSlide].content.questions) {
        if (question.type === 'dependent') {
          question.goodResponse = question.goodResponse.replaceAll(`[${oldVal}]`, `[${newVal}]`)
        }
      }
    },
    copyToClipBoard(text) {
      navigator.clipboard.writeText(`[${text}]`)
    },

    // rightClick
    addToRightClick() {
      this.rightClickItems[1] = {
        text: 'Supprimer la slide',
        method: this.delSlide
      }
    },
    delToRightClick() {
      this.rightClickItems[1] = null
    },

    // display
    checkScreen() {
      setTimeout(() => {
        if (window.innerWidth < window.innerHeight && window.innerWidth < 1000)
          Swal.fire({
            text: 'Il est conseillé de passer votre écran en mode paysage.',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
      },0)
    }
  }
}
</script>

<style scoped lang="scss">
.slide-btn {
  text-transform: none !important;
  word-spacing: 0.2em;
  border-radius: 0.5em;
}

.carousel {
  border: solid var(--v-lighter-base) 1px;
}

.variable:focus {
  padding: 0 8px;
  outline: solid 1px var(--v-text-base);
  border-radius: 4px;
}

.slide-content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  .scroller {
    height: 100%;
    overflow-y: auto;
  }
}
.bottom-menu {
  bottom: 0;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  width: 100%;
  background-color: var(--v-background-base);
  div {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
</style>

<style>
.SaveFormModal-dialog {
  max-height: 100%!important;
}
@media (max-height: 672px) {
  .SaveFormModal-dialog {
    height: 100%!important;
  }
}
</style>